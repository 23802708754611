<template>
  <div class="dp-pro-recipe-balloon">
    <h3 class="dp-pro-recipe-balloon__title">{{ title }}</h3>
    <p class="dp-pro-recipe-balloon__text">{{ text }}</p>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    title: {
      type: String,
      default: ''
    },

    text: {
      type: String,
      default: ''
    }
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-pro-recipe-balloon {
  position: relative;
  margin-bottom: 16px;
  padding: 16px;
  background-color: variables.$dpWhite01;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    $arrow-size: 12px;

    position: absolute;
    bottom: calc(-#{$arrow-size} / 2);
    left: 30px;
    content: '';
    width: $arrow-size;
    height: $arrow-size;
    background-color: variables.$dpWhite01;
    transform: translateX(-50%) rotate(45deg) skew(7deg, 7deg);
  }

  > * {
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  &__title {
    margin-bottom: 8px;
  }

  &__text {
    white-space: pre-wrap;
    line-height: 1.4;
  }
}
</style>
